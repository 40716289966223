<template>
  <v-app>
    <!--v-app-bar app clipped-left clipped-right flat floating tile class="pa-0" height="110px"-->
    <Header title="AXXEZ Dashboard">
      <template v-slot:subheader>
          <v-breadcrumbs v-if="$auth.loggedin" class="pa-0" :items="actualPath" large>
            <!--template v-slot:item="{ item }">
              <v-breadcrumbs-item
                :href="item.href"
                :disabled="item.disabled"
              >
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template-->
          </v-breadcrumbs>
      </template>
    </Header>
    <!--/v-app-bar-->
    <v-navigation-drawer v-if="$auth.loggedin" app clipped color="menu" v-model="drawer" :mini-variant="mini" disable-resize-watcher disable-route-watcher permanent>
      <v-list-item class="px-2">
        <v-btn icon @click.stop="mini = !mini" class="mr-3">
          <v-icon>{{mini ? 'fas fa-bars' : 'fas fa-list'}}</v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item dense v-if="actualMenu != null" link @click="closeMenu()">
        <v-list-item-icon v-if="actualMenu.parent != null">
          <v-icon>fas fa-chevron-left</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{actualMenu.text}}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>{{actualMenu.icon}}</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-show="item.roles == null || $auth.hasOneRole(item.roles)" v-for="(item, i) in menuItems" :key="i" link :to="item.to" @click="openMenu(item)">
          <v-list-item-icon>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{item.text}}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon v-if="item.menu != null">
           <v-icon>fas fa-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-progress-linear :active="$db.loading" :indeterminate="true" absolute top />
      <v-alert class="mx-3 mt-4" border="left" colored-border type="info" elevation="5" color="accent" tile prominent>
        Das Dashboard befindet sich immer noch im Aufbau. Momentan stehen leider noch nicht alle Features zur Verfügung oder funktionieren nicht wie erwartet.<br>
        <b>Wir bitten daher um euer Verständnis.</b>
      </v-alert>
      <router-view v-if="$auth.loggedin" />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data: () => ({
    drawer: false,
    mini: false,
    menu: 'main',
    menus: [
      //{ parent: null, text: "Dashboard", icon: null, menu: 'main' },
      { parent: 'main', text: "Home", icon: "fas fa-th-large", to: { name: 'home' } },
      { parent: 'main', text: "Shop", icon: "fas fa-shopping-cart", to: { name: 'shop' }, roles: ['community_member', 'team_member'] },

      { parent: 'main', text: "Homepage Admin", icon: "fas fa-home", menu: 'homepage', roles: ['homepage_admin', 'social_moderator' ] },
      { parent: 'homepage', text: "News", icon: "fas fa-newspaper", to: { name: 'homepage_news' } },
      { parent: 'homepage', text: "Media", icon: "fas fa-photo-video", to: { name: 'homepage_media' } },
      { parent: 'homepage', text: "Games", icon: "fas fa-gamepad", to: { name: 'homepage_games' }, roles: ['homepage_admin'] },
      { parent: 'homepage', text: "Teams", icon: "fas fa-users", to: { name: 'homepage_teams' }, roles: ['homepage_admin'] },

      { parent: 'main', text: "System", icon: "fas fa-sliders-h", menu: 'system', roles: ['system_admin'] },
      { parent: 'system', text: "Benutzer", icon: "fas fa-user-friends", to: { name: 'system_users' } },
      { parent: 'system', text: "Shop", icon: "fas fa-shopping-cart", to: { name: 'system_shop' } },
      { parent: 'system', text: "Dateien", icon: "fas fa-copy", to: { name: 'system_files' } },

      { parent: 'main', text: "Einstellungen", icon: "fas fa-cog", menu: 'settings' },
      { parent: 'settings', text: "Account", icon: "fas fa-key", to: { name: 'settings_account' } },
      { parent: 'settings', text: "Community Profil", icon: "fas fa-address-card", to: { name: 'settings_profile_community' }, roles: ['community_member'] },
      { parent: 'settings', text: "Team Profil", icon: "fas fa-address-card", to: { name: 'settings_profile_team' }, roles: ['team_member'] },
    ],
  }),
  computed: {
    actualItem() {
      return this.menus.find(item => item.to != null && item.to.name == this.$route.name);
    },
    actualMenu() {
      return this.menus.find(item => item.menu == this.menu);
    },
    menuItems() {
      return this.menus.filter(item => item.parent == this.menu);
    },
    actualPath() {
      var path = [];
      var item = this.actualItem;
      if(item != null){
        path.push(item);
        while(item.parent != null && item.parent != 'main'){
          var prevItem = this.menus.find(i => i.menu != null && i.menu == item.parent);
          path.unshift(prevItem);
          item = prevItem;
        }
      }
      path.unshift( { text: "Dashboard", icon: "fas fa-home", to: { name: 'home' } } );
      return path;
    },
  },
  watch: {
    actualItem(value){
      //console.log(`Item changed`);
      //console.log(value);
      if(value != null){
        this.menu = value.parent;
      }
    },
    '$auth.initialized'(val) {
      if(val && !this.$auth.loggedin){
        window.location.href = "https://axxez.eu";
      }
    }
  },
  methods: {
    hasAccess(roles) {
      return this.$auth.hasOneRole(roles);
    },
    openMenu (item) {
      if(item.menu != null){
        this.menu = item.menu;
      }
    },
    closeMenu() {
      if(this.actualMenu.parent != null) {
        this.menu = this.actualMenu.parent;
      }
    }
  },
  mounted() {
    //console.log("mounted");
  }
};
</script>

<style>

#app {
    background-color: var(--v-background-base);
}

</style>
