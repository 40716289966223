import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  /*{
    name: 'login',
    path: '/',
    component: () => import('@/views/Login.vue'),
  },*/
  {
    name: 'default',
    path: '*',
    redirect: '/home'
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('@/views/Home.vue'),
  },
  {
    name: 'shop',
    path: '/shop',
    component: () => import('@/views/Shop.vue'),
  },


  {
    name: 'homepage_news',
    path: '/homepage/news',
    component: () => import('@/views/homepage/News.vue'),
  },
  {
    name: 'homepage_media',
    path: '/homepage/media',
    component: () => import('@/views/homepage/Media.vue'),
  },
  {
    name: 'homepage_games',
    path: '/homepage/games',
    component: () => import('@/views/homepage/Games.vue'),
  },
  {
    name: 'homepage_teams',
    path: '/homepage/teams',
    component: () => import('@/views/homepage/Teams.vue'),
  },


  {
    name: 'system_users',
    path: '/system/users',
    component: () => import('@/views/system/Users.vue'),
  },
  {
    name: 'system_shop',
    path: '/system/shop',
    component: () => import('@/views/system/Shop.vue'),
  },
  {
    name: 'system_files',
    path: '/system/files',
    component: () => import('@/views/system/Files.vue'),
  },


  {
    name: 'settings_account',
    path: '/settings/account',
    component: () => import('@/views/settings/Account.vue'),
  },
  {
    name: 'settings_profile_community',
    path: '/settings/profile_community',
    component: () => import('@/views/settings/ProfileCommunity.vue'),
  },
  {
    name: 'settings_profile_team',
    path: '/settings/profile_team',
    component: () => import('@/views/settings/ProfileTeam.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(!Vue.prototype.$auth.loggedin){
    console.log("no loggedin");
  }
  /*if (!Vue.prototype.$auth.loggedin && to.name != 'login') { //redirect to login
    //const loginpath = window.location.pathname;
    next({ name: 'login', query: { return: to.path } });
  }
  else if(Vue.prototype.$auth.loggedin && to.name == 'login') { //redirect to home
    next({ name: 'home' });
  }
  else*/ next();
});

export default router
